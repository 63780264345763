import * as React from "react";
import DateTimePicker from 'react-datetime-picker';
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';

const minDate = new Date("1900-01-01");
const maxDate = new Date("2100-12-31");

export const DateSelector: React.FC<
  {
    datetimeHandler: any;
  }
> = ({
  datetimeHandler
}) => {
  const [fromDatetime, setFromDatetime] = useState(null);
  const [toDatetime, setToDatetime] = useState(null);

  const validDate = (date: Date) => {
    if (!date) return date

    if (date.getTime() - minDate.getTime() < 0) return minDate
    if (date.getTime() - maxDate.getTime() > 0) return maxDate
  
    return date
  }

  const onChangeFromDate = (val: Date) => {
    const date = validDate(val)
    setFromDatetime(date)
  }

  const onChangeToDate = (val: Date) => {
    const date = validDate(val)
    setToDatetime(date)
  }

  useEffect(() => {
    datetimeHandler(fromDatetime, toDatetime);
  }, [fromDatetime, toDatetime]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Button
          onClick={() => {
            setFromDatetime(null);
            setToDatetime(null);
            datetimeHandler(null, null);
          }}
          variant="secondary"
          style={{ marginRight: "10px" }}
        >
          Clear Dates
        </Button>
        <label style={{ marginRight: "10px" }}>From:</label>
        <DateTimePicker
          onChange={onChangeFromDate}
          value={fromDatetime}
          yearPlaceholder={"yyyy"}
          monthPlaceholder={"mm"}
          dayPlaceholder={"dd"}
          disableClock={true}
          format={"y-MM-dd"}
          maxDate={toDatetime}
        />
        <label style={{ marginRight: "10px", marginLeft: "10px" }}>To:</label>
        <DateTimePicker
          onChange={onChangeToDate}
          value={toDatetime}
          yearPlaceholder={"yyyy"}
          monthPlaceholder={"mm"}
          dayPlaceholder={"dd"}
          disableClock={true}
          format={"y-MM-dd"}
          minDate={fromDatetime}
        />
      </div>
    </>
  );
};
