import * as React from 'react';
import { Modal, Button, ModalProps } from 'react-bootstrap';
import QrCodeReader from './QrCodeReader';
import { SearchBarProps } from './SearchBar';
import { SearchByContactData, SearchType } from './SearchByContactData';

interface SearchModalProps extends ModalProps, SearchBarProps {
  searchType: SearchType;
}

const searchModalContent = {
  qrcode: {
    heading: 'Find by qrcode',
    title: 'Enter qrcode',
    promptValue:''
  },
  email: {
    heading: 'Find by email',
    title: 'Enter email',
    promptValue: 'email'
  },
  phone: {
    heading: 'Find by phone',
    title: 'Enter phone',
    promptValue: 'phone'
  },
  subjectid: {
    heading: 'Find by subject ID',
    title: 'Enter subject ID',
    promptValue: 'subject ID'
  },
};

export const SearchModal: React.FC<SearchModalProps> = ({
  searchType,
  searchHandler,
  ...props
}) => {
  const renderSearchContent = () => {
    switch (searchType) {
      case 'qrcode':
        return <QrCodeReader searchHandler={searchHandler} />;
      default:
        return (
          <SearchByContactData
            searchBy={searchType}
            searchPrompt={searchModalContent[searchType].promptValue}
            searchHandler={searchHandler}
          />
        );
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {searchModalContent[searchType].heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderSearchContent()}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
